<template>
  <a-drawer width="60%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose" :destroyOnClose="true">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="参数名称" prop="configName">
        <a-input v-model="form.configName" placeholder="请输入" />
      </a-form-model-item>
      <a-form-model-item label="参数键名" prop="configKey"  >
        <a-input v-model="form.configKey" placeholder="请输入" :readOnly="readOnly" :disabled="readOnly" />
      </a-form-model-item>
      <a-form-model-item label="是否富文本"  prop="textType" >
        <a-radio-group v-model="form.textType">
          <a-radio v-for="(item, index) in this.customDict.TextTypeEnum" :value="item.type" :key="index">
            {{ item.name }}
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="参数键值" v-if="form.textType === 1" prop="configValue">
        <a-input v-model="form.configValue" placeholder="请输入" />
      </a-form-model-item>
      <a-form-model-item label="参数键值"  v-if="form.textType === 2" prop="configValue">
        <editor v-model="form.configValue" placeholder="请输入内容" />
      </a-form-model-item>
      <a-form-model-item label="参数键值"  v-if="form.textType === 3" prop="configValue">
          <!--  图片上传      -->
        <a-upload
          name="configValue"
          listType="picture-card"
          class="avatar-uploader"
          :multiple="false"
          :show-upload-list="false"
          :preview="handlePreview"
          :before-upload="beforeOssUpload"
          :customRequest="coverImgUpload">
          <img
            v-if="form.configValue"
            :src="form.configValue"
            alt="configValue"
            style="height: 102px; width: 102px;"/>
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>

      <a-form-model-item label="系统内置" prop="configType">
        <a-radio-group v-model="form.configType" button-style="solid">
          <a-radio-button v-for="(d, index) in typeOptions" :key="index" :value="d.value">
            {{ d.label }}
          </a-radio-button>
        </a-radio-group>
        <!-- <a-select placeholder="是否内置" v-model="form.configType">
          <a-select-option v-for="(d, index) in typeOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>
        </a-select> -->
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark">
        <a-input v-model="form.remark" placeholder="请输入备注" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>

import { getConfig, addConfig, updateConfig } from '@/api/system/config'
import Editor from '@/components/Editor'
import CustomDictTag from "@/components/DictCustomTag";
import {mapGetters} from 'vuex'
import {fileUpload} from "@/api/tool/common";
import {generateFilePath} from "@/api/tool/alioss";

export default {
  name: 'CreateForm',
  props: {
    typeOptions: {
      type: Array,
      required: true
    }
  },
  components: {
    Editor,
    CustomDictTag,
  },
  data () {
    return {
      readOnly : false,
      submitLoading: false,
      formTitle: '',
      loading: '',
      // 表单参数
      form: {
        configId: undefined,
        configName: undefined,
        configKey: undefined,
        textType: 0,
        configValue: undefined,
        configType: 'Y',
        remark: undefined
      },
      open: false,
      rules: {
        configName: [{ required: true, message: '参数名称不能为空', trigger: 'blur' }],
        configKey: [{ required: true, message: '参数键名不能为空', trigger: 'blur' }],
        configValue: [{ required: true, message: '参数键值不能为空', trigger: 'blur' }]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.form = {
        configId: undefined,
        configName: undefined,
        configKey: undefined,
        configValue: undefined,
        configType: 'Y',
        remark: undefined
      }
    },
     /** 新增按钮操作 */
    handleAdd () {
      this.reset()
      this.open = true
       this.readOnly = false
       this.formTitle = '添加参数'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.readOnly = true
      const configId = row ? row.configId : ids
      getConfig(configId).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改参数'
      })
    },
    // oss 上传开始
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    // 上传之前校验
    beforeOssUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 20
      // if (!isJPG) {
      //     this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 20MB!')
      }
      return isLt2M
    },
    // 上传覆盖默认的上传行为
    coverImgUpload(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'code'
      const url = _this.uploadAliYunOss(uploadFile.name, prefix)
      /*_this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          _this.form.masterImg = res
          _this.$message.success('上传成功')
        })
      })*/
      const formData = new FormData()
      formData.append('file', uploadFile, url)
      _this.$nextTick(() => {
        fileUpload(formData).then(res => {
          _this.form.configValue = res.data.fullPath
          _this.$message.success('上传成功')
        })
      })
    },
    /**
     * 文件上传到oss返回url地址
     * @param { file } 文件对象
     */
    uploadAliYunOss(fileName, prefix) {
      // 获取文件后缀
      const suffix = fileName.substring(fileName.lastIndexOf('.'))
      // 生成文件名称
      const filePath = generateFilePath(prefix, suffix)
      return filePath
    },
    // oss 上传结束


    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.configId !== undefined) {
            updateConfig(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addConfig(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
